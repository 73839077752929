import { Mainnet } from '@usedapp/core';
import { utils, providers } from 'ethers';
import Config from './config.json';

const { AlchemyProvider, InfuraProvider } = providers;
const address = {
  // Testing
  '0x5781B2cBAb643Ecb94067F6804Dc05a258F72046': 'USDT',
  '0x47E422D770c27257258B1968c659F64f22d66fac': 'BTC',
  '0xb2e13957c0E95c662Fa8524e812d10705088Fa57': 'ETH',
  '0x6540C55DA0e9516aeF716e613e7d94D8b2774aBd': 'SOL',
  //erc token with prod like decimal places
  '0xe4fd307c3C1a6121F76033057E2777d2F095e505': 'BTC',
  '0xc5c7f3aD42Cb02617A3ed2Fb159C247A4E6eEB98': 'USDT',
  //Polygon mainnet
  '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619': 'ETH',
  '0xc2132D05D31c914a87C6611C10748AEb04B58e8F': 'USDT',
  '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': 'USDC',
  '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6': 'BTC',
  '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4': 'SOL',

  //ETH mainnet
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599': 'BTC',
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2': 'ETH',
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48': 'USDC'
};

const CURRENCY_UNIT = {
  USDT: 6,
  USDC: 6,
  ETH: 18,
  BTC: 8,
  SOL: 18
};

let chainId = Mainnet.chainId;

function updateChainId(id) {
  chainId = id;
}

function getCurrentChainConfig() {
  return Config.chainConfigs.find(config => config.chainId === chainId).tokens;
}

function getCurrencyByToken(token) {
  const chainConfig = getCurrentChainConfig();
  const currency = Object.keys(chainConfig).find(ccy => chainConfig[ccy].addr === token);
  if (currency) return currency;
  return address[token];
}

function formatEther(wei, currency, config = {}) {
  currency = currency === 'USDT' ? 'USDC' : currency;
  let unit = config[currency] && config[currency].decimals;
  if (!unit) {
    unit = CURRENCY_UNIT[currency] || 18;
  }
  return utils.formatUnits(wei, unit);
}

function parseEther(ether, currency, config = {}) {
  currency = currency === 'USDT' ? 'USDC' : currency;
  let unit = config[currency] && config[currency].decimals;
  if (!unit) {
    unit = CURRENCY_UNIT[currency] || 18;
  }
  return utils.parseUnits(ether, unit);
}

function formatPrice(wei, baseCurrency, contraCurrency, config = {}) {
  let contraUnit = config[contraCurrency] && config[contraCurrency].decimals;
  let baseUnit = config[baseCurrency] && config[baseCurrency].decimals;
  if (!contraUnit) {
    contraUnit = CURRENCY_UNIT[contraCurrency];
  }
  if (!baseUnit) {
    baseUnit = CURRENCY_UNIT[baseCurrency];
  }
  const unit = 18 + contraUnit - baseUnit;
  return utils.formatUnits(wei, unit);
}

function parsePrice(ether, baseCurrency, contraCurrency, config = {}) {
  let contraUnit = config[contraCurrency] && config[contraCurrency].decimals;
  let baseUnit = config[baseCurrency] && config[baseCurrency].decimals;
  if (!contraUnit) {
    contraUnit = CURRENCY_UNIT[contraCurrency];
  }
  if (!baseUnit) {
    baseUnit = CURRENCY_UNIT[baseCurrency];
  }
  const unit = 18 + contraUnit - baseUnit;
  return utils.parseUnits(ether, unit);
}

const formatAddress = account => {
  return account ? account.substring(0, 6) + '...' + account.slice(-4) : '';
};

const formatTokenAddress = add => {
  if (!add) {
    return add;
  }
  const name = getCurrencyByToken(add);
  return `${name}(${formatAddress(add)})`;
};

const formatBaseAmount = (amt, record) => {
  const currency = getCurrencyByToken(
    record.isCall ? record.baseTokenAdd : record.contraTokenAdd
  );
  const chainConfig = getCurrentChainConfig();
  return formatEther(amt, currency, chainConfig).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

const formatTradePrice = (price, record) => {
  const baseCurrency = getCurrencyByToken(record.baseTokenAdd);
  const contraCurrency = getCurrencyByToken(record.contraTokenAdd);
  const chainConfig = getCurrentChainConfig();
  return formatPrice(price, baseCurrency, contraCurrency, chainConfig).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );
};

const formatDateTime = dateBigN => {
  const num = dateBigN.toNumber();
  const date = new Date(num * 1000);
  const year = date.getFullYear();
  const month = ('' + (date.getMonth() + 1)).padStart(2, '0');
  const day = ('' + date.getDate()).padStart(2, '0');
  const hours = ('' + date.getHours()).padStart(2, '0');
  const minutes = ('' + date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};
function getLockAddress(chainId){
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lockAddressURL = urlParams.get('lock');
  if(lockAddressURL){
    return lockAddressURL;
  }
  const {chainConfigs}=Config;
  const chainConfig=chainConfigs.find(config=>config.chainId===chainId);
  if(chainConfig && chainConfig.lockAddress){
    return chainConfig.lockAddress;
  }
  return '0x5781B2cBAb643Ecb94067F6804Dc05a258F72046';
  
}
function getProvider(chainId) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const useAlchemy = !urlParams.get('infura');
  let projectId = urlParams.get('projectId');
  const { infuraId, alchemyId } = Config;

  if (useAlchemy) {
    projectId = projectId || alchemyId;
    return new AlchemyProvider(chainId, projectId);
  } else {
    projectId = projectId || infuraId;
    return new InfuraProvider(chainId, { projectId });
  }
}

function connectContractToSigner(contract, options, library) {
  console.log('library', library);
  if (contract.signer) {
    return contract;
  }
  if (options === null || options === void 0 ? void 0 : options.signer) {
    return contract.connect(options.signer);
  }
  if (library === null || library === void 0 ? void 0 : library.getSigner()) {
    return contract.connect(library.getSigner());
  }
  throw new TypeError('No signer available in contract, options or library');
}

export {
  updateChainId,
  formatEther,
  parseEther,
  formatPrice,
  parsePrice,
  formatAddress,
  formatTokenAddress,
  formatBaseAmount,
  formatTradePrice,
  formatDateTime,
  getProvider,
  connectContractToSigner,
  getLockAddress
};
