import 'antd/dist/antd.css';
import './App.css';
import { Space, Table, Tag, Button, message } from 'antd';
import { useEthers } from '@usedapp/core';
import { Contract as DefiContract } from '@ethersproject/contracts';
import React, { useEffect, useMemo, useState } from 'react';
import { WalletOutlined } from '@ant-design/icons';
import LOCK from './OPT_LOCK.json';
import ERC20 from './OurToken.json';
import WETHABI from './WETH.json';
import {
  updateChainId,
  parseEther,
  formatAddress,
  formatTokenAddress,
  formatBaseAmount,
  formatTradePrice,
  formatDateTime,
  getProvider,
  connectContractToSigner,
  getLockAddress
} from './util';
import Config from './config.json';

const { abi: lockABI } = LOCK;
const { abi: ercABI } = ERC20;
const states = [
  'Pending', //PREMIUM_PENDING
  'PendingPurchase', //OPEN
  'Locked', //LOCKED
  'Setlling', //PRICE_CONFIRMING,
  'Dispute', //DISPUTE,
  'Delivered', //DELIVERED,
  'Expired', //EXPIRED,
  'Withdrawn' //WITHDRAW
];

const makeColumns = ({ buy, agree, dispute }) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: '_id',
    render: bigN => bigN.toNumber()
  },
  {
    title: 'Notional',
    dataIndex: 'notional',
    key: 'notional',
    render: formatBaseAmount
  },
  {
    title: 'Premium',
    dataIndex: 'premium',
    key: 'premium',
    render: formatBaseAmount
  },
  {
    title: 'Ccy1',
    dataIndex: 'baseTokenAdd',
    key: 'baseTokenAdd',
    render: formatTokenAddress
  },
  {
    title: 'Ccy2',
    dataIndex: 'contraTokenAdd',
    key: 'contraTokenAdd',
    render: formatTokenAddress
  },
  {
    title: 'CALL_PUT',
    dataIndex: 'isCall',
    key: 'isCall',
    render: isCall => (isCall ? 'CALL' : 'PUT')
  },
  {
    title: 'Maturity (YYYY-MM-DD)',
    dataIndex: 'maturity',
    key: 'maturity',
    render: formatDateTime
  },
  {
    title: 'Strike',
    dataIndex: 'strike',
    key: 'strike',
    render: formatTradePrice
  },
  {
    title: 'IndexPrice',
    dataIndex: 'indexPrice',
    key: 'indexPrice',
    render: formatTradePrice
  },
  {
    title: 'UpdatedTime',
    dataIndex: 'ts',
    key: 'ts',
    render: formatDateTime
  },
  {
    title: 'State',
    key: 'state',
    dataIndex: 'state',
    render: stateIdx => {
      const state = states[stateIdx];
      let color = 'green';
      if (state === 'Locked') {
        color = 'orange';
      }
      if (state === 'Dispute') {
        color = 'red';
      }
      return (
        <Tag color={color} key={state}>
          {state}
        </Tag>
      );
    }
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => {
      const state = states[record.state];
      if (state === 'PendingPurchase') {
        return (
          <Space size="middle">
            <a onClick={buy(record)}>Buy</a>
          </Space>
        );
      } else if (state === 'Setlling' || state === 'Dispute') {
        return (
          <Space size="middle">
            <a onClick={agree(record)}>Settle</a>
            <a onClick={dispute(record)}>Dispute</a>
          </Space>
        );
      }
    }
  }
];

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const accountOverride = urlParams.get('acc');
const chainIdOverride = urlParams.get('chainId')
  ? parseInt(urlParams.get('chainId'), 10)
  : undefined;
const isNative = (chainId, ercAddress) => {
  const { chainConfigs } = Config;
  const chainConfig = chainConfigs.find(config => config.chainId === chainId);
  if (chainConfig && chainConfig.tokens) {
    return Object.values(chainConfig.tokens).reduce((pre, cur) => {
      if (pre) {
        return pre;
      }
      return cur.addr === ercAddress && cur.native;
    }, false);
  }
  return false;
};

const App = () => {
  const {
    account: accountWallet,
    deactivate,
    chainId: chainIdFromEther,
    activateBrowserWallet,
    library
  } = useEthers();
  const chainId = chainIdOverride || chainIdFromEther;
  const account = accountOverride || accountWallet;
  updateChainId(chainId);
  const chainConfig = Config.chainConfigs.find(
    config => config.chainId === chainId
  );

  const provider = useMemo(() => {
    console.log('chain id is ', chainId);
    if (!chainId) {
      return null;
    }

    return getProvider(chainId);
  }, [chainId]);

  const [tradeList, setTradeList] = useState([]);
  const contractAddress = getLockAddress(chainId);

  useEffect(() => {
    if (!provider) {
      return;
    }
    if (!account) {
      return;
    }
    const fetchTrades = async () => {
      const contract = new DefiContract(contractAddress, lockABI, provider);
      console.log('fetching from ', contractAddress, account);
      const trades = await contract.getTradesFor(account, true);
      console.log(trades);
      setTradeList([...trades].reverse());
    };
    fetchTrades();
  }, [account, provider]);

  const buy = trade => async () => {
    const key = 'buy';
    try {
      const ercTokenAddress = trade.isCall
        ? trade.baseTokenAdd
        : trade.contraTokenAdd;
      const ercContract = new DefiContract(ercTokenAddress, ercABI, provider);
      const ercSigned = connectContractToSigner(
        ercContract,
        {
          transactionName: 'Approve ERC spending'
        },
        library
      );
      const checkBalance = async () => {
        const balance = await ercSigned.balanceOf(account);
        if (trade.premium.gt(balance)) {
          if (isNative(chainId, ercTokenAddress)) {
            message.loading({ content: 'Tokenize ETH to WETH', key });
            const weth = new DefiContract(ercTokenAddress, WETHABI, provider);
            const wethSigned = connectContractToSigner(
              weth,
              {
                transactionName: 'Tokenize ETH to WETH'
              },
              library
            );
            const transaction = await wethSigned.deposit({
              value: trade.premium.sub(balance)
            });
            await transaction.wait(1);
          } else {
            throw new Error(
              'Insufficient ERC balance at contract ' + ercTokenAddress
            );
          }
        }
      };
      const checkAllowance = async () => {
        const allowance = await ercSigned.allowance(account, contractAddress);
        if (trade.premium.gt(allowance)) {
          message.loading({ content: 'Approving ERC20', key });
          const approval = await ercSigned.approve(
            contractAddress,
            trade.premium
          );
          await approval.wait(1);
        }
      };
      message.loading({ content: 'Checking Balance', key });
      await checkBalance();
      message.loading({ content: 'Checking Token Allowance', key });
      await checkAllowance();
      message.loading({ content: 'ERC approved, buying option', key });
      const contract = new DefiContract(contractAddress, lockABI, provider);
      console.log('contract constructed');
      const contractSigned = connectContractToSigner(
        contract,
        {
          transactionName: 'Buy option trade'
        },
        library
      );
      console.log('contract connected to signer');
      const transaction = await contractSigned.buy(trade.id, {
        value: parseEther('0')
      });
      console.log('transaction sent');
      await transaction.wait(1);
      message.success({
        content: 'Purchase Successful, refresh to view',
        key,
        duration: 2
      });
    } catch (err) {
      message.error({
        content: 'Purchase failed ' + err.message,
        key,
        duration: 10
      });
    }
  };

  const agree = trade => async () => {
    const key = 'agree';
    try {
      message.loading({ content: 'agree trade index price', key });
      let ercTokenAddress = null;
      let amount = null;
      const ONE_ETHER = parseEther('1');
      if (trade.isCall && trade.indexPrice.gt(trade.strike)) {
        //ITM call
        ercTokenAddress = trade.contraTokenAdd;
        amount = trade.strike
          .mul(trade.notional.add(trade.premium))
          .div(ONE_ETHER);
      } else if (trade.indexPrice.lt(trade.strike) && !trade.isCall) {
        ercTokenAddress = trade.baseTokenAdd;
        amount = ONE_ETHER.mul(trade.notional.add(trade.premium)).div(
          trade.strike
        );
      }
      if (ercTokenAddress) {
        message.loading({
          content: 'option is ITM, exercising now',
          key
        });
        const ercContract = new DefiContract(ercTokenAddress, ercABI, provider);
        const ercSigned = connectContractToSigner(
          ercContract,
          {
            transactionName: 'Approve ERC spending'
          },
          library
        );
        const checkBalance = async () => {
          const balance = await ercSigned.balanceOf(account);
          if (amount.gt(balance)) {
            if (isNative(chainId, ercTokenAddress)) {
              message.loading({ content: 'Tokenize ETH to WETH', key });
              const weth = new DefiContract(ercTokenAddress, WETHABI, provider);
              const wethSigned = connectContractToSigner(
                weth,
                {
                  transactionName: 'Tokenize ETH to WETH'
                },
                library
              );
              const transaction = await wethSigned.deposit({
                value: amount.sub(balance)
              });
              await transaction.wait(1);
            } else {
              throw new Error(
                'Insufficient ERC balance at contract ' + ercTokenAddress
              );
            }
          }
        };
        const checkAllowance = async () => {
          const allowance = await ercSigned.allowance(account, contractAddress);
          if (amount.gt(allowance)) {
            message.loading({ content: 'Approving ERC20', key });
            const approval = await ercSigned.approve(contractAddress, amount);
            await approval.wait(1);
          }
        };
        message.loading({ content: 'Checking Balance', key });
        await checkBalance();
        message.loading({ content: 'Checking Token Allowance', key });
        await checkAllowance();
        message.loading({ content: 'ERC approved, buying option', key });
      }
      const contract = new DefiContract(contractAddress, lockABI, provider);
      const contractSigned = connectContractToSigner(
        contract,
        {
          transactionName: 'Agree index price'
        },
        library
      );
      const transaction = await contractSigned.agreeIndexPrice(trade.id);
      await transaction.wait(1);
      message.success({
        content: 'Index price agreed, refresh to view',
        key,
        duration: 2
      });
    } catch (err) {
      message.error({
        content: 'trade settlement failed ' + err.message,
        key,
        duration: 10
      });
    }
  };

  const dispute = trade => async () => {
    const key = 'dispute';
    try {
      message.loading({ content: 'dispute trade index price', key });
      const contract = new DefiContract(contractAddress, lockABI, provider);
      const contractSigned = connectContractToSigner(
        contract,
        {
          transactionName: 'Dispute index price'
        },
        library
      );
      const transaction = await contractSigned.disputeIndexPrice(trade.id);
      await transaction.wait(1);
      message.success({
        content: 'dispute trade index price completed',
        key,
        duration: 2
      });
    } catch (err) {
      message.error({
        content: 'dispute trade index price failed ' + err.message,
        key,
        duration: 10
      });
    }
  };
  const columns = makeColumns({ buy, agree, dispute });
  let chainName = '';
  if (chainId && account) {
    if (chainConfig && chainConfig.name) {
      chainName = chainConfig.name;
    } else {
      chainName = 'Unspported Chain!';
    }
  }

  return (
    <div>
      <div className="center toolbar">
        <Button
          onClick={account ? deactivate : activateBrowserWallet}
          type="primary"
          icon={<WalletOutlined />}
        >
          {account ? 'Disconnect' : 'Connect'}
        </Button>
        <div className="accountId">{chainName + ': '}</div>
        <div className="accountId">{formatAddress(account)}</div>
        <div className="flex1"></div>
      </div>
      {account ? (
        <Table columns={columns} dataSource={tradeList} rowKey="id" />
      ) : null}
    </div>
  );
};

export default App;
